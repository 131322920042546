<template>
  <div>
    <q-form ref="editForm">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-card title="개정" class="cardClassDetailForm revisionLayout" topClass="topcolor-orange" v-if="popupParam.psiDeviceEquipmentId">
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <div class="row">
                  <div class="col-1">
                    <c-text
                      :required="editable && isRevision"
                      :editable="editable && isRevision"
                      label="개정번호"
                      name="revisionNum"
                      v-model="data.revisionNum">
                    </c-text>
                  </div>
                  <div class="col-2">
                    <c-text
                      :editable="false"
                      label="개정일시"
                      name="regDtStr"
                      v-model="data.regDtStr">
                    </c-text>
                  </div>
                  <div class="col-1">
                    <c-text
                      :editable="false"
                      label="개정자"
                      name="regUserName"
                      v-model="data.regUserName">
                    </c-text>
                  </div>
                  <div class="col-4">
                    <c-moc 
                      :editable="editable && isRevision"
                      :isSubmit="saveCallData"
                      :document.sync="data"
                      :documentId="popupParam.psiDeviceEquipmentId"
                      documentTitle="deviceName"
                      mocRelatedTaskCd="RT00000001"
                      label="MOC번호"
                      name="sopMocId"
                      v-model="data.sopMocId"
                    />
                  </div>
                  <div class="col-4">
                    <c-text
                      :required="editable && isRevision"
                      :editable="editable && isRevision"
                      label="개정사유"
                      name="revisionContent"
                      v-model="data.revisionContent">
                    </c-text>
                  </div>
                </div>
              </div>
              <!-- <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" style="margin-top: -18px !important;" id="revdiv">
                <c-table
                  ref="revision"
                  title="개정이력"
                  tableId="revision"
                  topBorderClass="topcolor-orange"
                  :columns="gridrev.columns"
                  :data="gridrev.data"
                  gridHeight="150px"
                  :columnSetting="false"
                  :expandAll="false"
                  :isFullScreen="false"
                  :usePaging="false"
                  :hideBottom="true"
                  :filtering="false"
                  @rowClick="rowClickRev"
                >
                </c-table>
              </div> -->
            </template>
          </c-card>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-card title="장치 및 설비 기본정보" class="cardClassDetailForm">
            <template slot="card-select">
              <c-select
                :editable="!isRevision"
                v-if="popupParam.psiDeviceEquipmentId"
                :comboItems="gridrev.data"
                type="custom"
                typetext="개정이력"
                itemText="revisionNum"
                itemValue="psiDeviceEquipmentId"
                name="selectedPsiDeviceEquipmentId"
                label=""
                v-model="selectedPsiDeviceEquipmentId"
                @input="rowClickRev"
              ></c-select>
            </template>
            <template slot="card-button">
              <q-btn-group outline >
                <q-icon name="help" style="font-size: 20px; margin-right: 10px;"
                  class="custom-btn text-primary">
                  <q-tooltip anchor="bottom left" self="top left">
                    <div class="tooltipCustomTop">
                      {{ $language('(주)') }}
                    </div>
                    <div class="tooltipCustom">
                    {{ $language('① 압력용기, 증류탑, 반응기, 열교환기, 탱크류 등 고정기계에 해당합니다.') }}<br>
                    {{ $language('② 부속물은 증류탑의 충진물, 데미스터(Demister), 내부의 지지물 등을 말합니다.') }}<br>
                    {{ $language('③ 용량에는 장치 및 설비의 직경 및 높이 등을 기재합니다.') }}<br>
                    {{ $language('④ 열교환기류는 동체측과 튜브측을 구별하여 기재합니다.') }}<br>
                    {{ $language('⑤ 자켓이 있는 압력용기류는 동체측과 자켓측을 구별하여 기재합니다.') }}
                    </div>
                  </q-tooltip>
                </q-icon>
                <c-btn
                  v-show="(editable && popupParam.psiDeviceEquipmentId && !isRevision) && isEnalbed"
                  label="개정"
                  icon="restart_alt"
                  @btnClicked="SetRevision" />
                <c-btn
                  v-show="(editable && popupParam.psiDeviceEquipmentId && isRevision) && isEnalbed"
                  label="개정취소"
                  icon="restart_alt"
                  @btnClicked="CancelRevision" />
                <c-btn
                  v-if="editable && popupParam.psiDeviceEquipmentId && isEnalbed"
                  label="삭제"
                  icon="remove"
                  @btnClicked="deleteData"/>
                <c-btn
                  v-if="editable && isEnalbed"
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="data"
                  :mappingType="mappingType"
                  label="저장"
                  icon="save"
                  @beforeAction="saveData"
                  @btnCallback="saveCallback" />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-4">
                <c-text
                  :required="true"
                  :editable="editable"
                  label="장치번호"
                  name="equipmentNo"
                  v-model="data.equipmentNo">
                </c-text>
              </div>
              <div class="col-4">
                <c-text
                  :required="true"
                  :editable="editable"
                  label="장치명"
                  name="deviceName"
                  v-model="data.deviceName">
                </c-text>
              </div>
              <div class="col-4">
                <c-select
                  :required="true"
                  :editable="editable && !popupParam.psiDeviceEquipmentId"
                  codeGroupCd="DEVICE_TYPE_CD"
                  type="edit"
                  itemText="codeName"
                  itemValue="code"
                  name="deviceTypeCd"
                  label="장치분류"
                  v-model="data.deviceTypeCd"
                ></c-select>
              </div>
              <div class="col-3">
                <c-equip
                  :editable="editable"
                  label="설비"
                  name="equipmentCd"
                  v-model="data.equipmentCd">
                </c-equip>
              </div>
              <div class="col-3">
                <c-plant
                  :required="true"
                  :editable="editable"
                  type="edit"
                  name="plantCd"
                  v-model="data.plantCd" />
              </div>
              <div class="col-3">
                <c-process
                  :editable="editable"
                  label="관련공정"
                  multiple="single"
                  name="processCd"
                  v-model="data.processCd">
                </c-process>
              </div>
              <div class="col-3">
                <c-dept
                  :editable="editable"
                  label="관리부서"
                  name="deptCd"
                  v-model="data.deptCd">
                </c-dept>
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style="padding-top: 20px !important;">
          <c-upload 
            :attachInfo="attachInfo"
            :editable="editable"
            label="첨부파일">
          </c-upload>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style="margin-top: -20px !important;">
          <c-tab
            type="tabcard"
            :tabItems.sync="tabItems"
            :inlineLabel="true"
            v-model="tab"
            align="left"
          >
            <template v-slot:default="tab">
              <component
                :is="tab.component"
                :key="tab.tabKey"
                :param="tab.param"
                :item.sync="tab.item"
              />
            </template>
          </c-tab>
        </div>
      </div>
      <c-dialog :param="popupOptions"></c-dialog>
    </q-form>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
import { uid } from 'quasar'

export default {
  name: 'device-machine-detail',

  props: {
    popupParam: {
      type: Object,
      default: () => ({
        psiDeviceEquipmentId: '',
        psiEquipmentCd: '',
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      attachInfo: {
        isSubmit: '',
        taskClassCd: 'PSI_DEVICEEQUIPMENT',
        taskKey: '',
        beforeTaskKey: '',
        isRev: false,
      },
      selectedPsiDeviceEquipmentId: null,
      tab: 'devicePartCd1',
      tabItems: [],
      data: {
        psiDeviceEquipmentId: '',  // 장치일련번호
        plantCd: '',  // 사업장코드
        equipmentNo: '',  // 장치번호
        deviceName: '',  // 장치명
        equipmentCd: '',  // 설비코드
        deviceTypeCd: null,  // 장치분류
        deviceTypeName: '',  // 장치분류
        processCd: '',
        deptCd: '',
        revisionNum: '',
        groupId: '',
        sysRevision: 1,
        revisionContent: '제정',
        regDtStr: '',
        regUserName: '',
        sopMocId: '',
        detailItems: [],
      },
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '90%',
        top: '10px',
        param: {},
        closeCallback: null,
      },
      gridrev: {
        columns: [
          {
            name: 'revisionNum',
            field: 'revisionNum',
            label: '개정번호',
            align: 'center',
            style: 'width: 80px',
            sortable: true,
          },
          {
            name: 'revisionContent',
            field: 'revisionContent',
            label: '개정사유',
            align: 'left',
            sortable: true,
          },
          {
            name: 'regDtStr',
            field: 'regDtStr',
            label: '개정일시',
            style: 'width: 150px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'regUserName',
            field: 'regUserName',
            label: '개정자',
            align: 'center',
            style: 'width: 80px',
            sortable: true,
          },
        ],
        data: [],
      },
      saveUrl: transactionConfig.psi.pfi.device.mst.insert.url,
      mappingType: 'POST',
      insertUrl: '',
      updateUrl: '',
      deleteUrl: '',
      revlistUrl: '',
      editable: true,
      isSave: false,
      isdelete: false,
      isRevision: false,
      isEnalbed: true,
      saveCallData: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  watch: {
    'data.deviceTypeCd': function() {
      this.getDetail();
    }
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      if (this.popupParam.popeditable === undefined) {
        this.editable = this.$route.meta.editable;
        this.isEnalbed = true;
      } else if (this.popupParam.popeditable === true) {
        this.editable = this.$route.meta.editable;
        this.isEnalbed = true;
      } else {
        this.editable = false;
        this.isEnalbed = false;
      }
      this.detailUrl = selectConfig.psi.pfi.device.get.url;
      this.insertUrl = transactionConfig.psi.pfi.device.mst.insert.url;
      this.updateUrl = transactionConfig.psi.pfi.device.mst.update.url;
      this.deleteUrl = transactionConfig.psi.pfi.device.mst.delete.url;
      this.revlistUrl = selectConfig.psi.pfi.device.list.url + '/revs';
      this.getData();
    },
    getData() {
      this.isRevision = false;
      if (this.popupParam.psiDeviceEquipmentId) {
        this.$set(this.attachInfo, 'taskKey', this.popupParam.psiDeviceEquipmentId)
        this.getDetailData(this.popupParam.psiDeviceEquipmentId, true);
        this.selectedPsiDeviceEquipmentId = this.popupParam.psiDeviceEquipmentId
      } else {
        if (this.popupParam.psiEquipmentCd) {
          this.data.equipmentCd = this.popupParam.psiEquipmentCd;
        }
      }
    },
    getDetail() {
      this.tabItems = [];
      if (this.data.deviceTypeCd == 'DTC0000000') {
        // 장치구분 직접입력의 경우
        if(this.data.detailItems.length == 0) {
          this.data.detailItems = [];
          for(var i=0; i<2; i++) {
            this.data.detailItems.push({
              psiDeviceEquipmentId: '',  // 장치일련번호
              deviceTypeCd: this.data.deviceTypeCd,  // 장치
              devicePartCd: 'DIR' + (i+1),  // 장치구분
              devicePartName: '',  // 장치구분명
              inMaterials: '',  // 내용물
              volume: '',  // 용량
              pressOperation: '',  // 압력(MPa)-운전
              pressDesign: '',  // 압력(MPa)-설계
              tempOperation: '',  // 온도(℃)-운전
              tempDesign: '',  // 온도(℃)-설계
              materialMain: '',  // 사용재질-본체
              materialSubPart: '',  // 사용재질-부속품
              materialGasket: '',  // 사용재질-개스킷
              weldingEfficiency: '',  // 용접효율
              calculateThick: '',  // 계산두께(mm)
              corrosionAllowance: '',  // 부식여유(mm)
              useThick: '',  // 사용두께(mm)
              afterHeatTreatYn: 'N',  // 후열처리여부
              nondestructMethod: '',  // 비파괴검사방법
              nondestructPercent: '',  // 비파괴검사율(%)
              applicableLaws: '',  // 적용법령
              regUserId: this.$store.getters.user.userId,
              chgUserId: this.$store.getters.user.userId,
            });
            this.tabItems.push({ name: 'devicePartCd' + (i + 1), icon: 'info', label:'장치구분' + (i + 1), param: {deviceTypeCd: 'DTC0000000', psiDeviceEquipmentId:this.popupParam.psiDeviceEquipmentId, editable:this.editable}, component: () => import(`${'./deviceEquipmentDetailInfo.vue'}`), item: this.data.detailItems[i], tabKey: uid() });
          }          
        } else {
          let cnt = 0;
          this.$_.forEach(this.data.detailItems, item => {
            let _data = item
            this.tabItems.push({ name: 'devicePartCd' + (cnt + 1), icon: 'info', label:'장치구분' + (cnt + 1), param: {deviceTypeCd: 'DTC0000000', psiDeviceEquipmentId:this.popupParam.psiDeviceEquipmentId, editable:this.editable}, component: () => import(`${'./deviceEquipmentDetailInfo.vue'}`), item: _data, tabKey: uid() });
            cnt++;
          });
        }
      } else {
        this.$comm.getComboItems('DEVICE_PART_CD').then(_result => {
          _result = this.$_.filter(_result, {attrVal1 : this.data.deviceTypeCd });
          if(this.data.detailItems.length == 0) {
            this.data.detailItems = [];
            this.$_.forEach(_result, _item => {
              this.data.detailItems.push({
                psiDeviceEquipmentId: '',  // 장치일련번호
                deviceTypeCd: this.data.deviceTypeCd,  // 장치
                devicePartName: _item.codeName,  // 장치구분명
                devicePartCd: _item.code,  // 장치구분
                inMaterials: '',  // 내용물
                volume: '',  // 용량
                pressOperation: '',  // 압력(MPa)-운전
                pressDesign: '',  // 압력(MPa)-설계
                tempOperation: '',  // 온도(℃)-운전
                tempDesign: '',  // 온도(℃)-설계
                materialMain: '',  // 사용재질-본체
                materialSubPart: '',  // 사용재질-부속품
                materialGasket: '',  // 사용재질-개스킷
                weldingEfficiency: '',  // 용접효율
                calculateThick: '',  // 계산두께(mm)
                corrosionAllowance: '',  // 부식여유(mm)
                useThick: '',  // 사용두께(mm)
                afterHeatTreatYn: 'N',  // 후열처리여부
                nondestructMethod: '',  // 비파괴검사방법
                nondestructPercent: '',  // 비파괴검사율(%)
                applicableLaws: '',  // 적용법령
                regUserId: this.$store.getters.user.userId,
                chgUserId: this.$store.getters.user.userId,
              });
            });
          }
          if (_result.length == 1) {
            let _data = this.data.detailItems[0]
            this.tabItems.push({ name: 'devicePartCd1', icon: 'info', label: '장치 및 설비 상세', param: {deviceTypeCd: this.data.deviceTypeCd, psiDeviceEquipmentId:this.popupParam.psiDeviceEquipmentId, devicePartCd: _result[0].code, editable:this.editable, devicePartName:_result[0].codeName}, component: () => import(`${'./deviceEquipmentDetailInfo.vue'}`), item: _data, tabKey: uid() });
          } else {
            let cnt = 0;
            this.$_.forEach(_result, item => {
              let _data = this.data.detailItems[cnt]
              this.tabItems.push({ name: 'devicePartCd' + (cnt + 1), icon: 'info', label:item.codeName, param: {deviceTypeCd: this.data.deviceTypeCd, psiDeviceEquipmentId:this.popupParam.psiDeviceEquipmentId, devicePartCd: item.code, editable:this.editable, devicePartName:item.codeName}, component: () => import(`${'./deviceEquipmentDetailInfo.vue'}`), item: _data, tabKey: uid() });
              cnt++;
            });
          }
        });
      }
    },
    getDetailData(_psiDeviceEquipmentId, _check) {
      this.$http.url = this.$format(this.detailUrl, _psiDeviceEquipmentId);
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.data = _result.data;
        this.data.regDtStr = this.data.regDtStr.slice(0, 19)
        // 상세팝업 최초작성/최근수정 정보 노출
        this.$emit('setRegInfo', _result.data)
        this.isRevision = false;
        if (_check) {
          this.getRevList(_result.data.groupId);
        }
        if(_result.data.psiDeviceEquipmentId == this.popupParam.psiDeviceEquipmentId && this.isEnalbed) {
          this.editable = this.$route.meta.editable;
        } else {
          this.editable = false;
        }
      },);
    },
    saveData() {
      if (this.popupParam.psiDeviceEquipmentId) {
        this.saveUrl = this.updateUrl;
        this.mappingType = 'PUT';
      } else {
        this.saveUrl = this.insertUrl;
        this.mappingType = 'POST';
      }
      let saveMsg = '저장하시겠습니까?';  
      if (this.isRevision) {
        saveMsg = '현재버전을 개정하여 저장하시겠습니까?';
      }

      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: '확인', 
            message: saveMsg,
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.data.regUserId = this.$store.getters.user.userId;
              this.data.chgUserId = this.$store.getters.user.userId;
              this.isSave = !this.isSave;
              this.isdelete = false;
              // 개정시
              if (this.isRevision) {
                this.saveUrl = this.insertUrl;
                this.mappingType = 'POST';
                this.data.sysRevision = parseInt(this.data.sysRevision) + 1;
              }
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    deleteData() {
      window.getApp.$emit('CONFIRM', {
        title: '확인', 
        message: '삭제하시겠습니까?',
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.popupParam.psiDeviceEquipmentId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            // this.isdelete = !this.isdelete;
            // this.popupParam.psiDeviceEquipmentId = null;
            // this.getDetailData(null, false);
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('closePopup');
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.popupParam.psiDeviceEquipmentId = result.data.psiDeviceEquipmentId
      this.$set(this.attachInfo, 'taskKey', result.data.psiDeviceEquipmentId)
      this.$set(this.attachInfo, 'isSubmit', uid())
      this.saveCallData = uid();
      // 첨부파일 셋팅
      this.$set(this.attachInfo, 'beforeTaskKey', '')
      this.$set(this.attachInfo, 'isRev', false)
      this.getData();
      if (this.mappingType == 'POST') {
        this.getDetail();
      }
    },
    reloadTabItem(_equipmentCd) {
      this.popupParam.equipmentCd = _equipmentCd;
      this.tabItems = this.tabItems.concat(this.addTabItems);
    },
    SetRevision() {
      this.$http.url = selectConfig.com.revision.getversion;
      this.$http.param = {revisionNum: this.data.revisionNum}
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.data.revisionNum = _result.data;
        this.data.revisionContent = '';
        this.data.regDtStr = '';
        this.data.regUserName = '';
        this.data.sopMocId = '';
        this.isRevision = true;
        // 첨부파일 셋팅
        this.$set(this.attachInfo, 'taskKey', '')
        this.$set(this.attachInfo, 'beforeTaskKey', this.popupParam.psiDeviceEquipmentId)
        this.$set(this.attachInfo, 'isRev', true)
      });
    },
    CancelRevision() {
      this.isRevision = false;
      // 첨부파일 셋팅
      this.$set(this.attachInfo, 'taskKey', this.popupParam.psiDeviceEquipmentId)
      this.$set(this.attachInfo, 'beforeTaskKey', '')
      this.$set(this.attachInfo, 'isRev', false)
      this.getData();
    },
    getRevList(_groupId) {
      this.$http.url = this.revlistUrl;
      this.$http.type = 'GET';
      this.$http.param = {groupId: _groupId};
      this.$http.request((_result) => {
        this.gridrev.data = _result.data;
      },);
    },
    rowClickRev(row) {
      if (row) {
        if (row.psiDeviceEquipmentId != this.data.psiDeviceEquipmentId) {
          this.selectedPsiDeviceEquipmentId = row;
          this.getDetailData(this.selectedPsiDeviceEquipmentId, false);
          this.$set(this.attachInfo, 'taskKey', this.selectedPsiDeviceEquipmentId)
        }
      }
    },
  }
};
</script>
